<template>
  <v-container class="my-6">
    <div class="cmd-bars btn-cmd">
      <div>
        <v-textarea
          id="json_"
          v-model="json_"
          outlined
          v-show="showLog"
        />
      </div>
      <span style="margin: 5px"
        ><v-btn @click="showLog = !showLog">{{
          showLog ? "Hide Data" : "Show Data"
        }}</v-btn></span
      >
      <span style="margin: 5px"><v-btn @click="odtnew">New</v-btn></span>
      <span style="margin: 5px"><v-btn @click="odtclear">Clear</v-btn></span>
      <span style="margin: 5px"><v-btn @click="odtundo">Undo</v-btn></span>
      <span style="margin: 5px"><v-btn @click="odtredo">Redo</v-btn></span>
      <span style="margin: 5px"
        ><v-btn @click="odtsaveImg">Save Image</v-btn></span
      >
      <span style="margin: 5px"><v-btn @click="odtdebugin">Debug</v-btn></span>
    </div>
    <div class="pid-odontogram"></div>
    <div class="cmd btn-cmd">
      <v-row>
        <v-col v-for="(v, k) in cmd" :key="k">
          <v-btn
            v-for="(a, b) in v"
            style="margin: 5px"
            :key="b"
            @click="odtsetcommand(a)"
            block
            >{{ b }}</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div class="cmd-message" style="padding: 10px; font-weight: bold"></div>
  </v-container>
</template>
<script>
import Odontogram from '../plugins/odontogram'
export default {
  name: 'Odontogram',
  data () {
    return {
      odt: null,
      cmd: [],
      showLog: false,
      json_: JSON.stringify([
        {
          id: 18,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 48,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 24,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 34,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 64,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 74,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 17,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 47,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 25,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 35,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 65,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 75,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 16,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 46,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 1
        },
        {
          id: 26,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 36,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 15,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 45,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 2
        },
        {
          id: 55,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 85,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 27,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 37,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 14,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 2
        },
        {
          id: 44,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 3
        },
        {
          id: 54,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 84,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 28,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 38,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 13,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 1, center: 0 },
          ext: 0
        },
        {
          id: 43,
          sts: 0,
          fill: { top: 0, bottom: 1, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 53,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 83,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 21,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 2
        },
        {
          id: 31,
          sts: 1,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 61,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 71,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 12,
          sts: 0,
          fill: { top: 0, bottom: 1, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 42,
          sts: 0,
          fill: { top: 1, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 52,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 82,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 22,
          sts: 1,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 32,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 62,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 72,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 11,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 2
        },
        {
          id: 41,
          sts: 0,
          fill: { top: 3, bottom: 3, left: 3, right: 3, center: 3 },
          ext: 0
        },
        {
          id: 51,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 81,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 23,
          sts: 1,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 33,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 63,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        },
        {
          id: 73,
          sts: 0,
          fill: { top: 0, bottom: 0, left: 0, right: 0, center: 0 },
          ext: 0
        }
      ])
    }
  },
  mounted () {
    this.odt = Odontogram({
      pid: 'pid',
      obj: 'odontogram',
      message: 'cmd-message',
      syncValue: (value) => { this.json_ = value }
    })
    this.cmd = this.odt.getCommand()
    this.odt.debugin()
  },
  methods: {
    odtsetcommand (k) {
      this.odt.setcommand(k)
    },
    odtsaveImg () {
      this.odt.getPng(function (img) {
        console.log(img)
        return null
        // $.post('save.php', { imagedata: img }, function (result) {
        //   var res = JSON.parse(result)
        //   if (res.success) {
        //     window.open(res.filename, '_blank')
        //   }
        // })
      })
    },
    odtclear () {
      this.odt.clear()
    },
    odtnew () {
      this.odt.new()
    },
    odtundo () {
      this.odt.undo()
    },
    odtredo () {
      this.odt.redo()
    },
    odtdebugin () {
      // this.odt.debugin()
    }
  }
}
</script>
